
.btn.btn-primary.back-to-top-btn {
    position: fixed;
    display: block;
    bottom: 30px;
    right: 30px;
    width: 50px;
    height: 50px;
    min-width: 0;
    padding: 0;
    border-radius: 100%;
    opacity: 0;
    z-index: 1000;
    pointer-events: none;
    transition: opacity 200ms ease;

    &.visible {
        opacity: 1;
        pointer-events: all;
    }

    & i {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        font-size: 20px;
        font-weight: 600;
    }
}
